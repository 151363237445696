import { getAuth, signInAnonymously } from 'firebase/auth';

import { useAxiosInterceptors } from '../useAxiosInterceptors';
import useBpTokenStore from '@src/store/useBpTokenStore';
import { useCallback } from 'react';
import { useFirebaseUserIdToken } from './useFirebaseAuth.helper';
import { useQuery } from '@tanstack/react-query';

const useFirebaseAuth = (tenantId?: string) => {
  const { _hasBpTokenStoreHydrated, bpTokenExtractedUnverified } = useBpTokenStore();
  const auth = getAuth();

  const signOutOldTenantUser = useCallback(async () => {
    if (tenantId && tenantId !== auth.tenantId) {
      auth.tenantId = tenantId;
    }

    if (
      _hasBpTokenStoreHydrated &&
      tenantId &&
      bpTokenExtractedUnverified?.t &&
      tenantId !== bpTokenExtractedUnverified.t
    ) {
      await auth.signOut();
    }
  }, [tenantId, bpTokenExtractedUnverified?.t, _hasBpTokenStoreHydrated, auth]);
  signOutOldTenantUser();

  const {
    data: anonSignInResponse,
    isLoading: isFireBaseAnonSignInLoading,
    isSuccess: isFireBaseAnonSignInSuccessful,
    ...rest
  } = useQuery({
    queryKey: ['ANON_SIGN_IN_FIREBASE'],
    queryFn: () => signInAnonymously(auth),
    enabled: !!auth.tenantId && !!tenantId && tenantId === auth.tenantId,
    retry: 5,
  });

  const { userIdToken } = useFirebaseUserIdToken(auth.currentUser, isFireBaseAnonSignInSuccessful);
  const { isFirstBearerTokenHeaderReady, isFirstBpTokenHeaderReady } = useAxiosInterceptors(userIdToken);

  return {
    response: anonSignInResponse,
    ...rest,
    isFireBaseAnonSignInLoading,
    isFireBaseAnonSignInSuccessful,
    isFirebaseAuthReady: isFireBaseAnonSignInSuccessful && isFirstBearerTokenHeaderReady,
    isFirebaseAuthLoading: isFireBaseAnonSignInLoading || !isFirstBearerTokenHeaderReady,
    isFirstBpTokenHeaderReady,
    userIdToken,
  };
};

export { useFirebaseAuth };
