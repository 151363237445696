import { ENGINE_NAV_DATA, HTTP_STATUS } from '@src/constants';
import { ErrorBoundaryArgs, useErrorBoundary } from '@src/components/ErrorBoundary';
import { QK_APP_BASKET, QK_APP_BASKET_MUTATION, QK_GET_PAYMENT_OPTIONS } from '../queryKeys';
import { ReactNode, useEffect } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { BPAPI } from '@src/api/mockedApi';
import { BaseBasket } from './useBasket.types';
import { ExpiredBasketModalContent } from '@src/components/ModalContents/ExpiredBasketModalContent';
import { ServiceMessageVariants } from '@kaboodle-solutions/design-library';
import { ToastNotification } from '@components/ToastNotification';
import { UpdateBasketRequestBody } from '@src/interfaces/engineBasketPutRequest';
import { handleCheckCurrentStep } from './useBasket.helper';
import { isAxiosError } from 'axios';
import { queryClient } from '../queryClient';
import { setSentryTag } from '@src/lib/sentry/sentryUtils';
import { useBasketExpiryTimerStore } from '@src/store/useBasketExpiryTimerStore';
import { useBasketStore } from '@src/store/useBasketStore';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useModalStore } from '@src/store/useModalStore';
import { useNavStepperStages } from '@src/store/useNavStepperStages';
import { useNavigateWithQueryParams } from '@src/hooks/useNavigateWithQueryParams/useNavigateWithQueryParams';
import { useShallow } from 'zustand/react/shallow';

const nowTime = new Date().getTime();
const getBasket = () => BPAPI.get<BaseBasket | null>(`/basket?t=${nowTime}`);
const putBasket = (payload: Partial<UpdateBasketRequestBody>, currencyCode: string | undefined) =>
  BPAPI.put<BaseBasket>('/basket', { ...payload, currencyCode });
const patchBasket = (payload: Partial<UpdateBasketRequestBody>) => BPAPI.patch<BaseBasket>('/basket', payload);

function handleBasketError({
  error,
  showBoundary,
  openModal,
  modalStatus,
}: {
  error: unknown;
  showBoundary: (error: ErrorBoundaryArgs) => void;
  openModal: (content: ReactNode, canBeClosed?: boolean | undefined) => void;
  modalStatus: string;
}) {
  if (!isAxiosError(error)) return;

  if (error.response?.status === HTTP_STATUS.Gone) {
    if (modalStatus === 'open') return;
    openModal(<ExpiredBasketModalContent />, false);
    return;
  }

  if (error.response && error.response.status >= HTTP_STATUS.BadRequest) {
    showBoundary({ error: error.response.statusText, template: 'basketError' });
  }
}

export const useBasketQuery = (enabled = true) => {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { showBoundary } = useErrorBoundary();
  const { engines } = useInitResponseStore();
  const { generateSteps, steps } = useNavStepperStages();
  const { openModal, status } = useModalStore(
    useShallow((state) => ({ openModal: state.openModal, status: state.status }))
  );
  const { basketId, setBasketId, setBasketExpiryTimer } = useBasketExpiryTimerStore(
    useShallow((state) => ({
      basketId: state.basketId,
      setBasketId: state.setBasketId,
      setBasketExpiryTimer: state.setTimeRemaining,
    }))
  );

  const { data, error, isError, isSuccess, isPlaceholderData, ...rest } = useQuery({
    queryKey: [QK_APP_BASKET],
    queryFn: getBasket,
    enabled,
    staleTime: Infinity,
    retry: (failureCount, error) => {
      if (isAxiosError(error) && error.response?.status === HTTP_STATUS.Gone) {
        return false;
      }
      return failureCount <= 2;
    },
  });

  const basketIdChanged = basketId !== data?.data?.id;
  if (data?.data?.customerDetails?.id) {
    setSentryTag('customer_id', data?.data?.customerDetails?.id);
  }

  useEffect(() => {
    if (
      data?.status === HTTP_STATUS.Ok &&
      isSuccess &&
      !isPlaceholderData &&
      data?.data?.calculated?.timeRemaining &&
      basketIdChanged &&
      data.data.id
    ) {
      handleCheckCurrentStep(steps, navigateWithQueryParams);
      setBasketId(data.data.id);
      setSentryTag('basket_id', data.data.id);
      setBasketExpiryTimer(data.data.calculated.timeRemaining);
    }
  }, [
    basketIdChanged,
    data,
    isSuccess,
    isPlaceholderData,
    setBasketExpiryTimer,
    setBasketId,
    navigateWithQueryParams,
    steps,
  ]);

  useEffect(() => {
    if (isError) {
      handleBasketError({
        error,
        showBoundary,
        openModal,
        modalStatus: status,
      });
    }
  }, [isError, error, showBoundary, openModal, status]);

  // If the basket has a booking ref, send the user off to the booking success page
  useEffect(() => {
    if (data?.data?.bookingRef) {
      navigateWithQueryParams(ENGINE_NAV_DATA['BOOKING_STATUS'].route);
    }
  }, [data?.data?.bookingRef, navigateWithQueryParams]);

  useEffect(() => {
    generateSteps(data?.data, engines);
  }, [data?.data, generateSteps, engines]);

  if (data?.status === HTTP_STATUS.NoContent) {
    return {
      data: null,
      error,
      isError,
      isSuccess,
      isPlaceholderData,
      ...rest,
    };
  }

  return {
    data,
    error,
    isError,
    isSuccess,
    isPlaceholderData,
    ...rest,
  };
};

export const useBasketMutation = (args?: { patch?: boolean; engineName?: string }) => {
  const currency = useInitResponseStore((state) => state.currency);
  const currencyCode = currency?.code;
  const setBasketStore = useBasketStore((state) => state.setBasketStore);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { steps, generateSteps } = useNavStepperStages();
  const { engines } = useInitResponseStore();

  // Checks current step every time steps are updated
  useEffect(() => {
    handleCheckCurrentStep(steps, navigateWithQueryParams);
  }, [steps, navigateWithQueryParams]);

  return useMutation({
    mutationKey: [QK_APP_BASKET_MUTATION],
    mutationFn: (payload: Partial<UpdateBasketRequestBody>) => {
      if (args?.patch) return patchBasket(payload);
      return putBasket(payload, currencyCode);
    },
    onSuccess: async (data) => {
      if (data?.data?.id) {
        setSentryTag('basket_id', data.data.id);
      }

      setBasketStore({ mutateBasketStatus: 'success' });
      queryClient.setQueryData([QK_APP_BASKET], data);

      await queryClient.invalidateQueries({ queryKey: [QK_GET_PAYMENT_OPTIONS] });
      await queryClient.invalidateQueries({ queryKey: ['extras', 'accommodations'] });

      // Regenerates Stepper when validationErrors change
      generateSteps(data?.data, engines);
      if (args?.engineName === 'extras') {
        if (data.data?.errors) {
          data.data?.errors?.map((error) => {
            ToastNotification({
              testId: 'engine-state-basket-error-notification',
              content: error.message,
              variant: ServiceMessageVariants.Error,
              autoClose: false,
            });
          });
        }
        const validationErrorsForThisEngine = args?.engineName
          ? data.data?.validationErrors?.filter((error) => error.engine === args.engineName)
          : data.data?.validationErrors;

        if (validationErrorsForThisEngine) {
          validationErrorsForThisEngine.forEach((error) => {
            ToastNotification({
              testId: 'engine-state-basket-info-notification',
              content: error.message,
              variant: ServiceMessageVariants.Info,
              autoClose: false,
            });
          });
        }
      }
    },
    onError: () => {
      setBasketStore({ mutateBasketStatus: 'error' });
    },
    onMutate: () => {
      setBasketStore({ mutateBasketStatus: 'pending' });
    },
  });
};
