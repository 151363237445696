import { useQuery, queryOptions } from '@tanstack/react-query';
import { REFETCH_BOOKING_REF } from '@src/constants/constants';
import { QK_PAYMENT_INTENT } from '@src/api/queryKeys';
import { getPaymentIntent, getPaymentIntentStatus } from '@src/api/paymentIntent';

export const useGetPaymentIntent = () =>
  useQuery({
    queryKey: [QK_PAYMENT_INTENT],
    queryFn: getPaymentIntent,
  });

export const paymentIntentStatusQueryOptions = (refetchBookingRef?: typeof REFETCH_BOOKING_REF) =>
  queryOptions({
    queryKey: [QK_PAYMENT_INTENT, 'STATUS'],
    queryFn: getPaymentIntentStatus,
    refetchInterval: (data) => {
      const { state } = data;
      if (state.dataUpdateCount < 10 && !state.data?.data.bookingRef && refetchBookingRef === REFETCH_BOOKING_REF) {
        return 3000;
      } else {
        return false;
      }
    },
  });
