import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import * as Sentry from '@sentry/react';

import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { firebaseConfig } from './firebase/firebaseConfig';
import { getEnvironmentFromEnvironment } from './lib/getEnvironmentFromEnvironment';
import { initializeApp as initializeFirebaseApp } from 'firebase/app';
import { queryClient } from './api/queryClient';
import routes from './routes';

Sentry.init({
  dsn: 'https://5c0a263e52fd4fcbb512c3b76e422e11@o105411.ingest.us.sentry.io/4505045430829056',
  environment: getEnvironmentFromEnvironment(),
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // Disable replay integration for now
    // Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1,
  ...(process.env?.SENTRY_RELEASE_VERSION || process.env?.GIT_BRANCH
    ? {
        release: process.env?.SENTRY_RELEASE_VERSION || process.env?.GIT_BRANCH,
      }
    : {}),

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Disable replay integration for now
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function App() {
  initializeFirebaseApp(firebaseConfig);

  // useTracking();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={sentryCreateBrowserRouter(routes)} />
    </QueryClientProvider>
  );
}

export default App;
