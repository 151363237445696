import { Button, Heading } from '@kaboodle-solutions/design-library';

import { RESTART_ROUTE } from '@src/constants';
import { findFirstEngine } from '@src/lib/findFirstEngine';
import styles from './Templates.module.scss';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useNavigate } from 'react-router-dom';
import { useNavigateWithQueryParams } from '@src/hooks/useNavigateWithQueryParams/useNavigateWithQueryParams';
import { useRestartBooking } from '@src/hooks/useRestartBooking';
import { useTranslation } from 'react-i18next';

const ERROR_BOUNDARY_TEMPLATES = {
  default: (_error: string) => <GenericErrorTemplate />,
  // TODO: Create this error template
  noTokensFound: (_error: string) => <GenericErrorTemplate />,
  pageNotFound: (_error: string) => <PageNotFound />,
  basketError: (_error: string) => <BasketError />,
  paymentFailed: (_error: string) => <PaymentFailed />,
  zeroValueBasketError: (_error: string) => <ZeroValueBasketError />,
  packageNotOnSale: (_error: string) => <PackageNotOnSale />,
  initFailure: () => <InitFailure />,
};
type TEMPLATE_KEYS = keyof typeof ERROR_BOUNDARY_TEMPLATES;

/**
 * Helper component for generic styling of the title and error message
 */
function TitleAndMessage({ title, message }: { title: React.ReactNode; message: React.ReactNode }) {
  return (
    <>
      <Heading level="h3" isBold>
        {title}
      </Heading>
      <Heading level="h4" customClass={styles.message}>
        {message}
      </Heading>
    </>
  );
}

/**
 * Templates for use in the error boundary. Once created, they can be added to the
 * ERROR_BOUNDARY_TEMPLATES object for use.
 */

function GenericErrorTemplate() {
  const { t } = useTranslation('errors');
  const restartBooking = useRestartBooking();
  const { options, currency, engines } = useInitResponseStore(({ options, currency, engines }) => ({
    options,
    currency,
    engines,
  }));

  return (
    <section className={`${styles.templateWrapper}`}>
      <TitleAndMessage title={t('404Title')} message={t('cantResume')} />
      {Boolean(options && currency && engines) && (
        <Button id="restart-booking-btn" labelText={t('restartBooking')} onClick={() => restartBooking()} />
      )}
    </section>
  );
}

function InitFailure() {
  const { t } = useTranslation('errors');

  return (
    <section className={`${styles.templateWrapper}`}>
      <TitleAndMessage title={t('404Title')} message={t('initFailure')} />
    </section>
  );
}

function PageNotFound() {
  const { t } = useTranslation('errors');
  return (
    <section className={`${styles.templateWrapper}`}>
      <TitleAndMessage title={t('pageNotFound')} message={t('incorrectInfo')} />
    </section>
  );
}

function PackageNotOnSale() {
  const { t } = useTranslation('errors');
  return (
    <section className={`${styles.templateWrapper}`}>
      <TitleAndMessage title={t('notOnSale')} message={t('checkOtherEvents')} />
      <a href="https://kaboodle.com/events">Go to available events</a>
    </section>
  );
}

function BasketError() {
  const { t } = useTranslation('errors');
  const restartBooking = useRestartBooking();

  return (
    <section className={`${styles.templateWrapper}`}>
      <TitleAndMessage title={t('basketError')} message={t('tryAgain')} />
      <Button id="back-to-start-btn" labelText={t('restartBooking')} onClick={() => restartBooking()} />
    </section>
  );
}

function PaymentFailed() {
  const { t } = useTranslation('errors');
  const restartBooking = useRestartBooking();
  return (
    <section className={`${styles.templateWrapper}`}>
      <TitleAndMessage title={t('oops')} message={t('paymentFailed')} />
      <Button id="restart-booking-btn" labelText={t('restartBooking')} onClick={() => restartBooking()} />
    </section>
  );
}

function ZeroValueBasketError() {
  const { t } = useTranslation('errors');
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const navigate = useNavigate();

  const engines = useInitResponseStore((state) => state.engines);

  function handleClick() {
    const firstRoute = findFirstEngine(engines ?? [])?.route;
    navigateWithQueryParams(firstRoute || RESTART_ROUTE);
    navigate(0);
  }

  return (
    <section className={`${styles.templateWrapper}`}>
      <TitleAndMessage title={t('couldntConfirmBooking')} message={t('tryAgain')} />
      <Button labelText={t('backToStart')} onClick={handleClick} />
    </section>
  );
}

export { ERROR_BOUNDARY_TEMPLATES, GenericErrorTemplate };
export type { TEMPLATE_KEYS };
