import { EngineHandles, EngineNames } from '@src/constants';

import { BaseBasket } from '@src/api/useBasket/useBasket.types';
import { InitResponse } from '@src/api/useInit';
import { create } from 'zustand';
import { createStepsArray } from './useNavStepperStages.helper';

export type Step = {
  linkUrl: string;
  linkTitleKey: string;
  mandatory: boolean;
  engineName: EngineNames;
  engineHandle: EngineHandles;
  enableNavigation: boolean;
  valid: boolean;
};

export type Steps = Step[] | [];

export type NavStepperStagesStore = {
  steps: Steps;
  lastNavigableStep: Step | null;
  generateSteps: (basketData: BaseBasket | null | undefined, engines: InitResponse['engines'] | null) => void;
};

export const useNavStepperStages = create<NavStepperStagesStore>()((set) => ({
  steps: [],
  lastNavigableStep: null,
  generateSteps: (basketData, engines) => {
    if (!engines) {
      return set(() => ({ steps: [] }));
    }

    const stages = createStepsArray(basketData, engines);
    const lastNavigableStep = stages.findLast((step) => step.enableNavigation);

    return set(() => ({ steps: stages, lastNavigableStep }));
  },
}));
