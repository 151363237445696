import { forwardRef } from 'react';
import { Typography, Checkbox, type CheckboxProps } from '@kaboodle-solutions/design-library';
import { type ConsentProps } from '@src/interfaces/customerDetails';
import styles from './marketingConsents.module.scss';

export type Props = {
  item?: ConsentProps;
  name: string;
  testId?: string;
} & CheckboxProps;

const MarketingConsents = forwardRef<HTMLButtonElement, Props>(({ item, testId, ...rest }, ref) => {
  if (!item) return null;
  const { questionText, label, policyStatement, policyUrl } = item;

  return (
    <article
      aria-label={questionText ?? 'Consent to be accepted'}
      className={styles.consent}
      data-testid={`${testId}-wrapper`}
    >
      <Typography customClass={styles.consent_question}>{questionText}</Typography>
      {policyStatement && policyUrl && (
        <a
          href={policyUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.consent_policyStatement}
          data-testid={`${testId}-policyStatement`}
        >
          {policyStatement}
        </a>
      )}
      <div className={styles.consent_checkBox}>
        <Checkbox testId={`${testId}`} {...rest} ref={ref} label={label ?? ''} id={item.id.toString()} />
      </div>
      <hr />
    </article>
  );
});

MarketingConsents.displayName = 'MarketingConsent';
export default MarketingConsents;
