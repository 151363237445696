import React, { forwardRef } from 'react';

import styles from './Header.module.scss';

export interface Props {
  showHeader: boolean;
}
const Header = forwardRef<HTMLDivElement, Props>(({ showHeader }, ref) => {
  if (!showHeader) return null;

  return (
    <div className={styles.fixedHeader} ref={ref}>
      <div className={styles.fixedHeaderContent}>
        <img className={styles.clientLogo} alt="Client Logo" />
      </div>
    </div>
  );
});

Header.displayName = 'Header';
export default Header;
