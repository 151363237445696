import { BasketPaymentSelector, Button } from '@kaboodle-solutions/design-library';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BasketCart } from '@src/components';
import { DEVICE_TABLET_BREAKPOINT } from '@src/constants';
import { FaShoppingBasket } from 'react-icons/fa';
import classNames from 'classnames';
import styles from './BasketSection.module.scss';
import { useBasketQuery } from '@src/api/useBasket';
import { useBasketStore } from '@src/store/useBasketStore';
import useCurrencyFormatter from '@src/hooks/useCurrencyFormatter/useCurrencyFormatter';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useLoadingStore } from '@src/store/useLoadingStore';
import useScreenBreakpoint from '@src/hooks/useScreenBreakpoint';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDynamicNavigate } from '@src/hooks/useDynamicNavigate';
import tracking from '@src/lib/tracking';

// Used in conjunction with css slide-out animation
const cartDisplayTimeout = 500;

const BasketSection = () => {
  const { options } = useInitResponseStore(({ options }) => ({
    options,
  }));

  const { t } = useTranslation(['common', 'basket']);
  const { format } = useCurrencyFormatter();
  const { data: basketData, isLoading: isBasketLoading } = useBasketQuery();
  const location = useLocation();
  const { navigateToNextEngine } = useDynamicNavigate();

  const {
    continueButtonCallback,
    continueButtonVariant,
    currentEngine,
    getBasketRequestState,
    isTicketStateDirty,
    enableContinueButton,
    mutateBasketStatus,
    showBasket,
    setBasketStore,
    showCompleteBookingLabel,
    showMonthlyPrice,
    toggleMonthlyPrice,
    updateBasketNavigation,
  } = useBasketStore();

  useEffect(() => {
    updateBasketNavigation(navigateToNextEngine, location.pathname);
  }, [location, updateBasketNavigation, navigateToNextEngine]);

  const [isExpanded, setIsExpanded] = useState<boolean>(showBasket);
  const { screenWidth } = useScreenBreakpoint();
  const isOnMobile = screenWidth < DEVICE_TABLET_BREAKPOINT;
  const showOnDesktop = !isOnMobile && !showBasket;

  useEffect(() => {
    setBasketStore({ showMonthlyPrice: options?.DISPLAY_PRICE_PRIORITY === 3 });
  }, [options?.DISPLAY_PRICE_PRIORITY, setBasketStore]);

  const setShowBasket = useCallback(
    (value: boolean) => {
      setBasketStore({
        showBasket: value,
      });
    },
    [setBasketStore]
  );

  const handleAddToBasket = useCallback(() => {
    getBasketRequestState ? getBasketRequestState() : undefined;
  }, [getBasketRequestState]);

  const toggleBasket = () => {
    setIsExpanded(!isExpanded);
  };

  // Based on `isExpanded`, if false and on mobile allow the CSS transiton to happen before
  // updating basket store state
  useEffect(() => {
    if (!isExpanded) {
      setTimeout(
        () => {
          setShowBasket(false);
        },
        isOnMobile ? cartDisplayTimeout : 0
      );
    } else {
      setShowBasket(true);
    }
  }, [isExpanded, setShowBasket, isOnMobile]);

  // Ensure the local `isExpanded` state is always in sync with
  // Basket Store's `showBasket`.
  useEffect(() => {
    setIsExpanded(showBasket);
  }, [showBasket, setIsExpanded]);

  const handleContinue = useCallback(() => {
    if (continueButtonCallback) continueButtonCallback();
    setIsExpanded(false);
    tracking.sendEvent({ event: 'basket_continue', engine: currentEngine || '', route: location.pathname });
  }, [continueButtonCallback, setIsExpanded, currentEngine, location.pathname]);

  useEffect(() => {
    if (
      !basketData?.data?.tickets?.length &&
      !basketData?.data?.extras?.length &&
      !basketData?.data?.accommodations?.length
    ) {
      setShowBasket(false);
    }
  }, [basketData?.data?.tickets, basketData?.data?.extras, basketData?.data?.accommodations, setShowBasket]);

  const addToBasketLabel = useMemo(() => {
    if (mutateBasketStatus === 'pending') {
      return { label: t('common:loading'), testId: 'loading' };
    }

    return { label: t('common:addToBasket'), testId: 'add-to-basket' };
  }, [t, mutateBasketStatus]);

  const ticketsLoaded = useLoadingStore((state) => !state.loadingSources.has('ticketsLoading'));
  const basketLoaded = !isBasketLoading;

  if (!ticketsLoaded || !basketLoaded) {
    return null;
  }

  return (
    <>
      {/* Expanded Cart */}
      {showBasket && <BasketCart handleContinue={handleContinue} isExpanded={isExpanded} toggleBasket={toggleBasket} />}
      {/* Collapsed Basket/Empty Basket (Desktop) || Basket/Empty Basket Footer Mobile */}
      {(showOnDesktop || isOnMobile) && (
        <div
          className={classNames(styles.basketContainer, {
            [styles.basketContainer__overlay]: showBasket,
          })}
        >
          {/* Add to basket button section */}
          <div
            className={classNames(
              {
                [styles.showUpdateBasketContainer]: isTicketStateDirty,
                [styles.hideUpdateBasketContainer]: !isTicketStateDirty,
              },
              styles.updateBasketContainer
            )}
          >
            <Button
              id="basket-section-add-to-basket-btn"
              testId={`basket-section-${addToBasketLabel.testId}-btn`}
              leftIcon={<FaShoppingBasket />}
              disabled={!isTicketStateDirty || mutateBasketStatus === 'pending'}
              onClick={handleAddToBasket}
              labelText={addToBasketLabel.label}
              variant="primary"
            />
          </div>

          <div className={styles.basketContent}>
            <div className={styles.viewBasketCompleteBookingWrapper}>
              <div className={styles.viewBasketCompleteBooking_container}>
                {/* Basket Toggle & Payment Total */}
                <div className={styles.viewBasketAndTotal}>
                  <BasketPaymentSelector
                    basketValue={
                      showMonthlyPrice
                        ? `${format(basketData?.data?.calculated?.total?.monthlyPrice ?? 0)} ${t(
                            'basket:prePaymentPerMonth'
                          )}`
                        : format(basketData?.data?.calculated?.total?.value ?? 0)
                    }
                    showPaymentSwitch={!!basketData?.data?.calculated?.total?.monthlyPrice}
                    onToggleBasket={toggleBasket}
                    onToggleSwitch={toggleMonthlyPrice}
                    labelTotal={showMonthlyPrice ? 'Pre-payment plan' : 'Total'}
                    fullWidth={!isOnMobile}
                    disabled={
                      !basketData?.data?.tickets?.length &&
                      !basketData?.data?.extras?.length &&
                      !basketData?.data?.accommodations?.length
                    }
                    testId="basket-section-view-basket-selector"
                  />
                </div>
                {/* Continue Desktop */}
                <div className={styles.basketContainer_footer}>
                  <div className={styles.basketContainer_continueWrapper}>
                    {!isOnMobile && (
                      <Button
                        id="basket-section-continue-btn"
                        disabled={!enableContinueButton}
                        testId="basket-section-continue-btn"
                        labelText={showCompleteBookingLabel ? t('common:completeBooking') : t('common:continue')}
                        onClick={handleContinue}
                        size="wide"
                        variant={isTicketStateDirty ? 'secondary' : continueButtonVariant}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* Continue Mobile */}
              {isOnMobile && (
                <div className={styles.basketContainer_continueWrapper}>
                  <Button
                    id="basket-section-continue-btn"
                    disabled={!enableContinueButton}
                    testId="basket-section-continue-btn"
                    labelText={showCompleteBookingLabel ? t('common:completeBooking') : t('common:continue')}
                    onClick={handleContinue}
                    size="md"
                    variant={isTicketStateDirty ? 'secondary' : continueButtonVariant}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BasketSection;
