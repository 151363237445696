import { KapiEngineHandlesOptions } from '@src/interfaces/engineData';

export enum BPEngineHandlesOptions {
  BASKET = KapiEngineHandlesOptions.BASKET,
  BOOKING_STATUS = 'BOOKING_STATUS',
  EXTRAS = KapiEngineHandlesOptions.EXTRAS,
  LOGIN = KapiEngineHandlesOptions.LOGIN,
  PAYMENT = KapiEngineHandlesOptions.PAYMENT,
  TICKET = KapiEngineHandlesOptions.TICKET,
  ACCOMMODATION = KapiEngineHandlesOptions.ACCOMMODATION,
  TRANSPORT = KapiEngineHandlesOptions.TRANSPORT,
}

const createEngine = <K extends object = object, T = object>(
  handle: BPEngineHandlesOptions,
  engineName: string,
  route: string,
  protectedRoute = false,
  layoutOverrides = {} as K,
  subRoutes: T = {} as T
) => ({
  handle,
  engineName,
  layout: {
    columns: 2,
    displayNav: true,
    ...layoutOverrides,
  },
  name: `navigation:${engineName}`,
  protected: protectedRoute,
  route,
  subRoutes,
});

const ticketsEngine = createEngine(BPEngineHandlesOptions.TICKET, 'tickets', '/tickets');
const extrasEngine = createEngine(BPEngineHandlesOptions.EXTRAS, 'extras', '/extras');
const accommodationEngine = createEngine(BPEngineHandlesOptions.ACCOMMODATION, 'accommodation', '/accommodation');
const transportEngine = createEngine(BPEngineHandlesOptions.TRANSPORT, 'transport', '/transport');
const paymentEngine = createEngine(BPEngineHandlesOptions.PAYMENT, 'payment', '/payment', true);
const bookingStatusEngine = createEngine(
  BPEngineHandlesOptions.BOOKING_STATUS,
  'bookingStatus',
  '/booking-status',
  true,
  { columns: 1, showSideBar: false, engineContainerShouldGrow: false }
);

const loginEngineSubRoutes = {
  newCustomer: createEngine(BPEngineHandlesOptions.LOGIN, 'newCustomer', '/customer-details/new-here'),
  loginRoute: createEngine(BPEngineHandlesOptions.LOGIN, 'login', '/customer-details/login'),
  forgottenPassword: createEngine(
    BPEngineHandlesOptions.LOGIN,
    'forgottenPassword',
    '/customer-details/forgotten-password'
  ),
};
const loginEngine = createEngine(
  BPEngineHandlesOptions.LOGIN,
  'customerDetails',
  '/customer-details',
  true,
  {},
  loginEngineSubRoutes
);

/**
 * This is the data that we use to determine many of the engine features.
 * It's used to determine the layout, the columns, the navigation stepper and
 * the sidebar.
 */
export const ENGINE_NAV_DATA = {
  [BPEngineHandlesOptions.TICKET]: ticketsEngine,
  [BPEngineHandlesOptions.EXTRAS]: extrasEngine,
  [BPEngineHandlesOptions.ACCOMMODATION]: accommodationEngine,
  [BPEngineHandlesOptions.TRANSPORT]: transportEngine,
  [BPEngineHandlesOptions.LOGIN]: loginEngine,
  [BPEngineHandlesOptions.PAYMENT]: paymentEngine,
  [BPEngineHandlesOptions.BOOKING_STATUS]: bookingStatusEngine,
} as const;

export type EngineNavDataKeys = keyof typeof ENGINE_NAV_DATA;
// Basket is treated as an engine in validation errors
export type EngineNames = (typeof ENGINE_NAV_DATA)[EngineNavDataKeys]['engineName'] | 'basket';
export type EngineHandles = `${BPEngineHandlesOptions}`;
export type EngineNavDataHandles = (typeof ENGINE_NAV_DATA)[EngineNavDataKeys]['handle'];

export function isEngineNavDataKey(key: string): key is EngineNavDataKeys {
  return key in ENGINE_NAV_DATA;
}

/**
 * An iterable array of engines based on the declaration above. This is not
 * to be confused with the ordered array that we have in the init response store.
 * This array does not declare the order of engines for use in the navigation stepper
 * or other processes that need to be stepped through, but can simply be used for
 * easier type safe iteration.
 */
export const ENGINES = Object.keys(ENGINE_NAV_DATA).map((key) => {
  if (!isEngineNavDataKey(key)) {
    throw new Error(`Invalid engine key: ${key}`);
  }
  return ENGINE_NAV_DATA[key];
});

export const CUSTOMER_DETAILS_ROUTE = loginEngine.route;
export const LOGIN_ROUTE = loginEngine.subRoutes.loginRoute.route;
export const NEW_CUSTOMER_ROUTE = loginEngine.subRoutes.newCustomer.route;
export const FORGOTTEN_PASSWORD_ROUTE = loginEngine.subRoutes.forgottenPassword.route;
export const BOOKING_STATUS_ROUTE = bookingStatusEngine.route;
