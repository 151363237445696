import { BPAPI } from '@src/api/mockedApi';
import { KapiEngineHandles } from '@src/interfaces/engineData';
import { termsAndConditionsHandles } from '@src/interfaces/BPAPI';

export interface InitResponse {
  client: {
    id: number;
    name: string;
  };
  package: {
    id: number;
    name: string;
    description: string;
    price: {
      value: number;
      bookingFee: number;
    };
  };
  currency: {
    code: 'GBP' | 'EUR';
    exponent: number;
  };
  currencyOptions: {
    code: string;
    exponent: number;
  }[];
  engines: {
    id: number;
    name: string;
    handle: KapiEngineHandles;
    mandatory: boolean;
    messages?: string[];
  }[];
  options: {
    NKBF_MAXTICKETS: number;
    STRIPE_PUBLIC_API: string;
    ENABLE_KLARNA_STRIPE_ELEMENTS: boolean;
    PAYMENTS_AMEX_ENABLED: boolean;
    WEGLOT_TRANSLATION: boolean;
    GTM_IDS: string;
    DISPLAY_PRICE_PRIORITY: number | null;
  };
  termsAndConditions: {
    handle: termsAndConditionsHandles;
    packageId: string;
    text: string;
    url: string;
  }[];
}

export const getInit = (initParams: URLSearchParams) => BPAPI.get<InitResponse>(`/init?${initParams?.toString()}`);
