import { useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';

const useCurrencyFormatter = () => {
  const { currency, _hasInitResponseStoreHydrated } = useInitResponseStore(
    useShallow(({ currency, _hasInitResponseStoreHydrated }) => ({
      currency,
      _hasInitResponseStoreHydrated,
    }))
  );
  const isSuccess = _hasInitResponseStoreHydrated && currency !== null;
  const isError = _hasInitResponseStoreHydrated && currency === null;
  const isLoading = _hasInitResponseStoreHydrated === false;

  const locale = useMemo(() => navigator.language ?? 'en-GB', []);
  const decimalPlaces = useMemo(() => currency?.exponent ?? 2, [currency?.exponent]);
  const currencyCode = useMemo(() => currency?.code ?? 'GBP', [currency?.code]);

  const format = useCallback(
    (value: number) => {
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol',
      });
      return formatter?.format(value / 10 ** decimalPlaces);
    },
    [decimalPlaces, currencyCode, locale]
  );

  return {
    format,
    isFormatFunctionReady: isSuccess,
    formatFunctionHasError: isError,
    isFormatFunctionLoading: isLoading,
  };
};

export default useCurrencyFormatter;
