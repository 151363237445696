import { openDB } from 'idb';
import Cookies from 'js-cookie';
import { findAvailableLocalStores } from '@src/lib/findAvailableLocalStores';

async function getTokenFromStore(tokenType: string) {
  const availableStore = findAvailableLocalStores();
  if (availableStore.isIndexDBAvailable) {
    const db = await openDB(tokenType, 1);

    const dbExists = db.objectStoreNames.contains(tokenType);
    if (dbExists) {
      const token = await db.get(tokenType, tokenType);
      if (token) return token;
    }
  }

  if (availableStore.isLocalStorageAvailable) {
    const token = localStorage.getItem(tokenType);
    if (token) return token;
  }

  if (availableStore.isCookieAvailable) {
    const token = Cookies.get(tokenType);
    if (token) return token;
  }
}

const retrieveAppToken = async (tokenType: string): Promise<string | undefined> => {
  try {
    const token = await getTokenFromStore(tokenType);
    return token;
  } catch (error) {
    console.error('Error retrieving BP token from local store:', error);
  }

  return undefined;
};

export { retrieveAppToken };
