function findAvailableLocalStores() {
  const isIndexDBAvailable = 'indexedDB' in window;
  const isLocalStorageAvailable = 'localStorage' in window;
  const isCookieAvailable = 'cookie' in window;
  return {
    isIndexDBAvailable,
    isLocalStorageAvailable,
    isCookieAvailable,
  };
}

export { findAvailableLocalStores };
