import { type User } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';

const refetchIntervalWindowInSeconds = 3 * 60 * 1000;

export const useFirebaseUserIdToken = (currentUser: User | null, isFireBaseAnonSignInSuccessful: boolean) => {
  const [timesFetched, setTimesFetched] = useState(0);
  const [userIdToken, setUserIdToken] = useState<string | null>(null);

  const getFirebaseUserId = useCallback(async () => {
    await currentUser?.getIdToken().then((token) => {
      setUserIdToken(token);
    });
  }, [setUserIdToken, currentUser]);

  useEffect(() => {
    const refetchUserIdInterval = setInterval(() => {
      setTimesFetched((state) => state + 1);
    }, refetchIntervalWindowInSeconds);

    return () => {
      clearInterval(refetchUserIdInterval);
    };
  }, []);

  useEffect(() => {
    if (isFireBaseAnonSignInSuccessful) {
      getFirebaseUserId();
    }
  }, [timesFetched, currentUser, currentUser?.tenantId, isFireBaseAnonSignInSuccessful, getFirebaseUserId]);

  return { userIdToken };
};
