import { useEffect } from 'react';

const useBasketHeightAdjustment = (isOnMobile: boolean, isExpanded: boolean) => {
  const updateBasketWrapperHeight = () => {
    const basketWrapper = document.querySelector('.basketCartOpen') as HTMLElement | null;
    const basketWrapperinner = document.querySelector('.basketWrapper') as HTMLElement | null;
    const basketContent = document.querySelector('.basketContent') as HTMLElement | null;
    const basketFooter = document.querySelector('.basketContainer') as HTMLElement | null;
    const basketHeader = document.querySelector('.basketHeaderWrapper') as HTMLElement | null;

    const viewportHeight = window.innerHeight;

    // Proceed only if all required elements are found
    if (basketHeader && basketFooter && basketWrapper && basketWrapperinner && basketContent) {
      const headerHeight = basketHeader.offsetHeight;
      const footerHeight = basketFooter.offsetHeight;

      // Calculate available height for the content
      const availableHeight = viewportHeight - (headerHeight + footerHeight);
      const doubleFooter = footerHeight * 2;
      const overallContentHeight = availableHeight - doubleFooter;

      let totalHeight = 0;

      Array.from(basketContent.children).forEach((child) => {
        totalHeight += (child as HTMLElement).offsetHeight;
      });

      // Set max-height of basketWrapper and basketContent based on calculations
      basketWrapper.style.maxHeight = `${viewportHeight - footerHeight}px`;
      basketContent.style.maxHeight = `${overallContentHeight}px`;
      basketContent.style.height = `${totalHeight}px`;
    }
  };

  // Attach the event listener for 'resize'.
  window.addEventListener('resize', updateBasketWrapperHeight);

  useEffect(() => {
    if (!isOnMobile) {
      return;
    }

    // Initial calculation on page load
    updateBasketWrapperHeight();

    return () => {
      window.removeEventListener('resize', updateBasketWrapperHeight);
    };
  }, [isOnMobile, isExpanded]);
};

export default useBasketHeightAdjustment;
