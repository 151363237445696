import { useCallback } from 'react';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useLoadingStore } from '@src/store/useLoadingStore';
import { useNavigate } from 'react-router-dom';

/**
 * This hook is responsible for persisting the init params after navigation
 */

export const useNavigateWithQueryParams = () => {
  const navigate = useNavigate();
  const { initParams } = useInitResponseStore();
  const setLoading = useLoadingStore((state) => state.setLoading);

  const navigateWithQueryParams = useCallback(
    (path: string | number, resetLoadingState = true) => {
      if (resetLoadingState) {
        setLoading('appLoading', false);
      }
      navigate(`${path}${initParams || ''}`);
    },
    [navigate, initParams, setLoading]
  );

  return { navigateWithQueryParams };
};
