import { create } from 'zustand';

interface LoadingState {
  loadingSources: Map<string, boolean>;
  setLoading: (source: string, isLoading: boolean) => void;
  isLoading: () => boolean;
}

export const useLoadingStore = create<LoadingState>((set, get) => ({
  loadingSources: new Map([['appLoading', true]]),
  setLoading: (source: string, isLoading: boolean) => {
    const { loadingSources } = get();
    if (isLoading) {
      loadingSources.set(source, isLoading);
    } else {
      loadingSources.delete(source);
    }
    set({ loadingSources: new Map(loadingSources) });
  },
  isLoading: () => {
    const { loadingSources } = get();
    const requiredLoads = ['appLoading'];
    return requiredLoads.some((source) => loadingSources.get(source));
  },
}));
