import { openDB } from 'idb';
import Cookies from 'js-cookie';
import { findAvailableLocalStores } from '@src/lib/findAvailableLocalStores';

async function updateTokenStore(appToken: string | null, tokenType: string) {
  const availableStore = findAvailableLocalStores();
  if (!appToken) return;

  if (availableStore.isIndexDBAvailable) {
    const db = await openDB(tokenType, 1);

    const dbExists = db.objectStoreNames.contains(tokenType);
    if (dbExists) {
      await db.put(tokenType, appToken, tokenType);
      return;
    }
  }

  if (availableStore.isLocalStorageAvailable) {
    localStorage.setItem(tokenType, appToken);
    return;
  }

  if (availableStore.isCookieAvailable) {
    Cookies.set(tokenType, appToken, { expires: 3650 });
    return;
  }
}

// Change name and remove the other method once everything changes
export const storeAppToken = async (tokenType: string, appToken: string | null) => {
  try {
    await updateTokenStore(appToken, tokenType);
  } catch (error) {
    console.error('Error storing BP token in local store:', error);
  }
};

export default storeAppToken;
